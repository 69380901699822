import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
import { rooms } from '../../data/rooms';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Content mdxType="Content">
      <p>{`{rooms.standard2.price} €`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEA//EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAF6QOaoJw//xAAZEAEAAwEBAAAAAAAAAAAAAAABAAISESL/2gAIAQEAAQUCEJZ9TaW327af/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAARARAAL/2gAIAQEABj8C50qAD//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExQWH/2gAIAQEAAT8hrXdsa+IaXhPqJRJFZ6MnEf/aAAwDAQACAAMAAAAQZ+//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCn/8QAHRABAQACAQUAAAAAAAAAAAAAAREAQSFRcYGx4f/aAAgBAQABPxBRBokxG/csXqcvJwm8DYFVS9c1E0Lt4zcHcz//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/73421dde80f5d72df7120b2d53f27484/e93cc/kupelna-premium.webp 300w", "/static/73421dde80f5d72df7120b2d53f27484/b0544/kupelna-premium.webp 600w", "/static/73421dde80f5d72df7120b2d53f27484/68fc1/kupelna-premium.webp 1200w", "/static/73421dde80f5d72df7120b2d53f27484/a2303/kupelna-premium.webp 1800w", "/static/73421dde80f5d72df7120b2d53f27484/4293a/kupelna-premium.webp 2400w", "/static/73421dde80f5d72df7120b2d53f27484/666bd/kupelna-premium.webp 7283w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/73421dde80f5d72df7120b2d53f27484/73b64/kupelna-premium.jpg 300w", "/static/73421dde80f5d72df7120b2d53f27484/3ad8d/kupelna-premium.jpg 600w", "/static/73421dde80f5d72df7120b2d53f27484/6c738/kupelna-premium.jpg 1200w", "/static/73421dde80f5d72df7120b2d53f27484/8b34c/kupelna-premium.jpg 1800w", "/static/73421dde80f5d72df7120b2d53f27484/111a0/kupelna-premium.jpg 2400w", "/static/73421dde80f5d72df7120b2d53f27484/d0fb8/kupelna-premium.jpg 7283w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/73421dde80f5d72df7120b2d53f27484/6c738/kupelna-premium.jpg",
              "alt": "kupelna premium",
              "title": "kupelna premium",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      